import React from 'react';
import { Link } from 'gatsby';
import BareWidget from './bare';

const LinkWidget = ({ title, links }) => {
  return (
    <BareWidget title={title}>
      <ul>
        {links.map((link) => {
          return (
            <li key={link.to}><Link to={link.to} key={link.to} >{link.title}</Link></li>
          );
        })}
      </ul>
    </BareWidget>
  );
};

export default LinkWidget;
