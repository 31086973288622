import styled from 'styled-components';

const FooterContainer = styled.div`
  background-color: #333;
  color: grey;
  padding: 1.5rem 0 0rem;
  text-align: right;
`;


export {
  FooterContainer,
};
