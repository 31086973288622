import styled from 'styled-components';
import { Container, Col } from 'react-bootstrap';

const Main = styled(Container)`
  margin-top: 1.8rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
`;

const MidBody = styled(Col)`
  padding-right: 2.5rem;
  h1,h2,h3,h4 {
    font-weight: 700;
    margin-bottom: 1.2rem;
    margin-top: 1.8rem;
    color: var(--color-pallate2);
  }
  h1 {
    line-height: 2.5rem;
  }

  blockquote {
    background: #eee;
    border-left: 10px solid #ccc;
    margin: 1.5em 20px;
    padding: 1em 10px .1em 20px;
  }

  code {
    background-color: var(--color-pallate5);
    color: #ffffff;
    font-weight: bold;
    padding: 0.25rem 0.5rem;;
    border-radius: 0.75rem;
    font-size: 0.75rem;
  }

  
/*
  p > a {
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
*/
`;

const RightBody = styled(Col)`
  border-left: 1px solid #ccc;
  @media (max-width: 767px) {
    border-left: none;
  }
`;


export {
  Main,
  MidBody,
  RightBody,
};

