import React from 'react';
import '../../scss/custom.scss';
import '../../scss/custom.css';
import { Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Header from './header';
import Footer from './footer';
import { Main, MidBody, RightBody } from './styled';
import { BareWidget, LinkWidget } from './../widgets/leftWidget';

const Layout = ({ children }) => {
  const query = graphql`
    query latestArticles {
      allMdx(sort: {order: DESC, fields: frontmatter___date}, limit: 10) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM Do, YYYY")
              title
            }
            slug
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);
  const articles = data.allMdx.edges;

  const latestArticleLinks = articles.map((article) => {
    const { title } = article.node.frontmatter;
    const { slug } = article.node;
    return {
      to: `/${slug}`,
      title,
    }
  });

  return (
    <>
      <Header />
      <Main>
        <Row>
          <MidBody md={9} lg={8}>
            {children}
          </MidBody>
          <RightBody md={3} lg={4}>
            <LinkWidget title="บทความล่าสุด" links={latestArticleLinks} />
          </RightBody>
        </Row>
      </Main>
      <Footer />
    </>
  );
};

export default Layout;
