import React from 'react';
import { WidgetContainer } from './styled';

const BareWidget = ({ children, title }) => {
  return (
    <WidgetContainer>
      <h4 className="title">{title}</h4>
      <div className="content">
        {children}
      </div>
    </WidgetContainer>
  );
};

export default BareWidget;
