import styled from 'styled-components';

const WidgetContainer = styled.div`
  h4.title {
    background-color: var(--color-pallate1);
    color: #fff;

    padding: 0.3rem 1.2rem; 
    font-size: 1.1rem;
    font-weight: bold;
  }

  .content {
    padding: 0rem 0.85rem;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-left: 0;
  }

  li {
    border-bottom: 1px dashed #eed;
    padding: 0.5rem 0;
    line-height: 1rem;
    padding-left: 1.5rem;
    text-indent: -0.5rem;
  }

  .content li:before {
    display: inline-block;
    color: #599;
    content: '»';
    margin-right: 0.5rem;
  }

  margin-top: 1rem;
`;


export {
  WidgetContainer,
}
