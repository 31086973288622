import React from 'react';
import {
  Container,
  Navbar,
  Nav,
} from 'react-bootstrap';

import NavbarWrapper from './styled.js';


const Header = () => {
  return (
    <NavbarWrapper expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand className="brand" href="/">Ninenote.net</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="links" className="me-auto">
            <Nav.Link href="/">หน้าแรก</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </NavbarWrapper>
  );
};

export default Header;
