import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

const NavbarWrapper = styled(Navbar)`

  background-color: var(--color-pallate1);

  .navbar-nav {
    a.nav-link {
      color: var(--color-pallate3);
      font-weight: bold;
      :hover {
        color: #fff;
      }
    }
  }

  a.brand {
    font-weight: bold;
    background: -webkit-linear-gradient(#fff, #888);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    :hover {
      background: -webkit-linear-gradient(#888, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 

    }
  }


`;

export default NavbarWrapper;
