import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FooterContainer } from './styled';


const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col>
            <p>Copyright © {new Date().getFullYear()}. All rights reserved - Ninenote.net</p>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
